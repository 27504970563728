import React,{ useState} from 'react'
import styles from "./Login.module.css"
import { login } from '../Validations/Authentication'
import axios from 'axios'
import Loader from './Utilities/Loader'
import {SECRET_KEY,BASE_URL} from "../Constants/index"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {

    const [errorMsg,setErrorMsg] = useState([]) 
    const [isLoading,setIsLoading] = useState(false)
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [passwordType,setPasswordType] = useState("password")

    const showHide = () =>{
        if(passwordType === "password"){
            setPasswordType("text")
        }else{
            setPasswordType("password")
        }
    }

    const loginHandler = (e) =>{
        e.preventDefault()
        const data = {
            email:email,
            password:password
        }

        const url = BASE_URL + "login"

        const config = {
            headers:{
                "secret_key":SECRET_KEY,
                "Content-Type" : "application/json"
            }
        }

        login.validate(data,{abortEarly:false})
        .then((result) =>{
            setIsLoading(true)
            axios.post(url,data,config)
            .then((response) =>{
                const result = JSON.parse(response.data)
                if(result.code === 1){
                    if(result.data.Account_Approved){
                        sessionStorage.setItem("merchantToken",result.data.token)
                        sessionStorage.setItem("merchantFname",result.data.FirstName)
                        sessionStorage.setItem("merchantLname",result.data.LastName)
                        sessionStorage.setItem("storeName",result.data.MerchantName)
                        sessionStorage.setItem("merchantEmail",result.data.MerchantEmailAddress)
                        sessionStorage.setItem("merchantId",result.data.id)
                        setIsLoading(false)
                    }else{
                        setIsLoading(false)
                    }
                }else{
                    setIsLoading(false)
                    toast.error(result.message)
                }
            }).catch((error) =>{
                setIsLoading(false)
                console.log(error)
            })
            setErrorMsg([])
        }).catch((error) =>{
            setErrorMsg(error.errors)
        })
    }

    const emailHandler = (e) =>{
        setEmail(e.target.value)
    }

    const passHandler = (e) =>{
        setPassword(e.target.value)
    }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <div className={styles.gifImg}>
              <img src="/images/food_anim.gif" alt="gif" />
            </div>
            <div className={styles.message}>
                <h1>Start saving</h1>
                <h1>your time & money</h1>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.loginForm}>
                <form>
                  <div className={styles.formContainer}>
                      <div className={styles.welcome}>
                          <h2>We are coming with bundles of Coupons...</h2>
                          <p>Collect More Grow More</p>
                      </div>    
                      <div className={styles.notHaveAcc}>
                          <span> Visit :- <a href='https://merchant.foodflyer.ca'> https://merchant.foodflyer.ca</a></span>
                      </div>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
      {
            isLoading && <Loader />
            
        }
        <ToastContainer 
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light" />
      </>
  )
}

export default Login