import React from 'react'
import style from "./Loader.module.css"


function Loader() {
  return (
    <div className={style.loaderContainer}>
        <div className={style.loader} style={{color:"#06C167"}}></div>
    </div>
  )
}

export default Loader