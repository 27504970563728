import './App.css';
import Login from "./Components/Login"

function App() {
  return (
    <Login />
  );
}

export default App;
