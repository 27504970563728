import * as yup from "yup"

export const login = yup.object().shape({
    email:yup.string().email({email:"**Invalid email address"}).required({email:"**This field is required"}),
    password:yup.string().min(5,{pass:"**Must be greater than 5 char"}).required()
})

export const register = yup.object().shape({
    email:yup.string().email({email:"**Invalid email address"}).required({email:"**This field is required"}),
    password:yup.string().min(5,{pass:"**Must be greater than 5 char"}).required(),
    first_name:yup.string().required({fname:"**This field is required"}),
    last_name:yup.string().required({lname:"**This field is required"}),
    phone: yup.string().min(10,{phone:"**Phone no. must be 10 char"}).max(10,{phone:"**Phone no. must be 10 char"}).required(),
    store_name:yup.string().required({store_name:"**This field is required"}),
})

export const updatePassword = yup.object().shape({
    old_password:yup.string().min(5,{old:"**Must be greater than 5 char"}).required(),
    new_password:yup.string().min(5,{new:"**Must be greater than 5 char"}).required(),
    confirm_password:yup.string().min(5,{conf:"**Must be greater than 5 char"}).required(),
})

export const forgot = yup.object().shape({
    email:yup.string().email({email:"**Invalid email address"}).required({email:"**This field is required"}),
})

export const forgotUpdate = yup.object({
    newPassword:yup.string().required().min(5,{passNew:"**Must be greater than 5 char"}),
    confPassword:yup.string().required().min(5,{passConf:"**Must be greater than 5 char"}),
})